// src/pages/ThankYou.js
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo-newA.webp";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #152a68;
  color: #ffffff;
  text-align: center;
  position: relative;

  h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .logo {
    width: 250px;
    padding: 1rem;
    margin-bottom: 30px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .back-button {
    background-color: #white;
    color: #152a68;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 38px;
    }

    p {
      font-size: 18px;
    }

    .back-button {
      font-size: 14px;
    }
  }
`;

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <Wrapper>
      <img className="logo" src={logo} alt="Logo" />
      <div>
        <h1>Thank You!</h1>
        <p>
          Your registration was successful. We will get in touch with you soon.
        </p>
        <button className="back-button" onClick={handleBackToHome}>
          Back to Main Page
        </button>
      </div>
    </Wrapper>
  );
};

export default ThankYouPage;
