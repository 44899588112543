import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Form, Button, Row, Col } from "react-bootstrap";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import background from "../assets/images/bannerbackA.webp";
import backgroundResp from "../assets/images/bannerbackRespA.webp";
import leftbot from "../assets/images/leftBotimgA.webp";
import logo from "../assets/images/logo-newA.webp";

const Wrapper = styled.section`
  width: 100%;
  min-height: 906px;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: 100%;
  .onlyMob {
    display: none;
  }

  .left .l3 {
    font-size: 8vw;
    font-weight: 800;
    color: #f7cb3b;
    margin: 0;
    padding: 0;
    line-height: auto !important;
  }

  .left .l2 {
    font-size: 4vw;
    font-weight: 800;
    color: white;
    width: max-content;
    margin-top: -2.5rem;
    margin-btoom: -3rem;
  }

  .left .l1 {
    font-size: 3vw;
    color: white;
    line-height: 4rem;
  }

  @media only screen and (min-width: 1025px) {
    .right {
      margin-right: 2rem !important;
    }

    .leftBot {
      margin-left: 0.5rem !important;
    }

    .leftBot img {
      width: 40% !important;
    }
  }

  @media only screen and (max-width: 1025px) {
    .leftBot {
      text-align: center !important;
      margin: 0 !important;
    }
  }

  .bannerCont {
    width: 100%;
    display: flex;
    padding-left: 100px;
    gap: 15vw;
    justify-content: center;
  }

  .paddingForm {
    padding: 0 25px;
  }
  .borderRad input {
    border-radius: 0px;
  }
  .borderRad select {
    border-radius: 0px;
  }
  .borderRad {
    border-radius: 0px;
  }
  .leftBot {
    margin-left: -24px;
  }
  .textCenter {
    text-align: center;
  }
  .form-container {
    background-color: #f5c543;
    padding: 8px 20px;
    position: relative;
    clip-path: polygon(
      80px 0,
      100% 0,
      100% calc(100% - 80px),
      calc(100% - 80px) 100%,
      0 100%,
      0 80px
    );
  }
  .form-title {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
  }
  .form-control {
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    padding: 12px;
    width: 100%;
  }
  .submit-button {
    background-color: #152a68;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
  }
  .error-message {
    color: red;
    font-size: 12px; /* Smaller font size */
    margin-bottom: 10px; /* Adjust spacing */
  }
  .logo {
    width: 240px;
    padding: 1rem;
  }
  // .bannerCont {
  //   width: 100%;
  //   display: flex;
  //   padding-left: 100px;
  //   gap: 30vw;
  // }
  // .left .l1 {
  //   font-size: 38px;
  //   font-weight: 400;
  //   color: white;
  //   margin-bottom: 0px;
  // }
  // .left .l2 {
  //   font-size: 48px;
  //   font-weight: 800;
  //   color: white;
  //   line-height: 58px;
  //   margin-bottom: 0px;
  // }
  // .left .l3 {
  //   font-size: 10vw;
  //   font-weight: 800;
  //   color: #f7cb3b;
  //   margin-bottom: 0px;
  //   width: max-content;
  // }
  .leftBot img {
    width: 30%;
  }
  .lresp1,
  .lresp2 {
    display: none;
  }
  .right .form {
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .form .form_h2 {
    font-weight: bold;
    font-size: 19px;
    padding-top: 35px;
    text-align: center;
  }
  .margin-bottom {
    margin-bottom: 10px;
  }
  .formButton {
    font-size: 16px;
  }
  .fntSize {
    font-size: 13px;
  }
  @media (max-width: 451px) {
    min-height: 810px;
    background-image: url(${backgroundResp});
    background-size: 100%;
    object-fit: cover;
    .hideMob {
      display: none;
    }
    .onlyMob {
      display: block;
    }
    .paddingForm {
      padding: 0;
    }
    .leftBot {
      margin-left: 0px;
    }
    .fntSize {
      font-size: 11px;
    }
    .lresp1,
    .lresp2 {
      display: block;
    }
    .lresp1 {
      font-size: 26px;
      font-weight: 400;
      color: white;
      margin-bottom: 0px;
      text-align: center;
    }
    .lresp2 {
      font-size: 8vw;
      font-weight: 700;
      color: #f7cb3b;
      line-height: 42px;
      text-align: center;
    }
    .l1,
    .l2,
    .l3 {
      display: none;
    }
    .bannerCont {
      flex-direction: column;
      padding-left: 0px;
      gap: 20px !important;
      justify-content: center;
    }
    .logo {
      width: 190px;
      padding: 0.5rem;
      .leftBot img {
        width: 100%;
        margin-left: -25px;
      }
    }
    .leftBot {
      margin-left: 50px;
    }
    .leftBot img {
      width: 30%;
      margin-left: -25px;
    }
    .form .form_h2 {
      font-weight: bold;
      font-size: 18px;
      padding-top: 32px;
      text-align: center;
    }
    .right {
      margin-left: 50px;
    }
  }
  @media (max-width: 1450px) {
    // .left .l1 {
    //   font-size: 38px;
    // }
    // .left .l2 {
    //   font-size: 40px;
    // }
    // .left .l3 {
    //   font-size: 10vw;
    // }
    // .bannerCont {
    //   gap: 180px;
    // }
    .l3 {
      width: max-content;
    }
  }
  @media (min-width: 1600px) {
    min-height: 1132px;
    // .bannerCont {
    //   width: 100%;
    //   display: flex;
    //   gap: 340px;
    //   padding-top: 50px;
    // }
    .form {
      margin-right: 40px;
    }
    .right {
      margin-left: -75px;
    }
    .overrideWidth {
      width: 100%;
    }
    // .left .l1 {
    //   font-size: 50px;
    //   font-weight: 500;
    //   color: white;
    // }
    // .left .l2 {
    //   font-size: 56px;
    //   font-weight: 800;
    //   color: white;
    //   line-height: 75px;
    // }
    // .left .l3 {
    //   font-size: 10vw;
    //   font-weight: 800;
    //   color: #f7cb3b;
    // }
    .form .form_h2 {
      font-weight: 700;
      font-size: 23px;
    }
    .formButton {
      font-size: 20px;
    }
    .fntSize {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .catch .logo {
      width: 60% !important;
    }
  }

  @media (min-width: 450px) and (max-width: 768px) {
    min-height: 610px;
    background-image: url(${backgroundResp});
    background-size: 100%;
    object-fit: cover;
    .bannerCont {
      flex-direction: column;
      padding-left: 0px;
      gap: 20px !important;
      justify-content: center;
    }
    .lresp1,
    .lresp2 {
      display: block;
      text-align: center;
    }
    .l1,
    .l2,
    .l3 {
      display: none;
    }
    .leftBot {
      text-align: center;
    }
    .leftBot img {
      width: 30%;
    }
    .form-row {
      justify-content: center;
    }
    .lresp1 {
      font-size: 33px;
      margin-bottom: 0px;
      color: white;
    }
    .lresp2 {
      font-size: 8vw;
      font-weight: 700;
      color: #f7cb3b;
      line-height: 42px;
      text-align: center;
    }
    .right {
      margin-bottom: 15px;
    }
  }
  @media (min-width: 575px) and (max-width: 650px) {
    .overrideWidth {
      width: 72%;
    }
  }
  @media (min-width: 650px) and (max-width: 768px) {
    .overrideWidth {
      width: 61%;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    min-height: 610px;
    background-image: url(${backgroundResp});
    background-size: 100%;
    object-fit: cover;
    .bannerCont {
      flex-direction: column;
      padding-left: 0px;
      gap: 20px !important;
      justify-content: center;
    }
    .lresp1,
    .lresp2 {
      display: block;
      text-align: center;
    }
    .l1,
    .l2,
    .l3 {
      display: none;
    }
    .leftBot {
      text-align: center;
    }
    .leftBot img {
      width: 30%;
    }
    .form-row {
      justify-content: center;
    }
    .lresp1 {
      font-size: 33px;
      margin-bottom: 0px;
      color: white;
    }
    .lresp2 {
      font-size: 48px;
      font-weight: 700;
      color: #f7cb3b;
      line-height: 42px;
      text-align: center;
    }
    .right {
      margin-bottom: 15px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1150px) {
    min-height: 625px;
    .bannerCont {
      gap: 10px;
      justify-content: space-between;
    }
    .overrideWidth {
      width: 90%;
    }
    // .left .l1 {
    //   font-size: 28px;
    //   font-weight: 400;
    //   color: white;
    //   margin-bottom: 0px;
    // }
    // .left .l2 {
    //   font-size: 29px;
    //   font-weight: 800;
    //   color: white;
    //   line-height: 32px;
    //   margin-bottom: 0px;
    // }
    // .left .l3 {
    //   font-size: 34px;
    //   font-weight: 800;
    //   color: #f7cb3b;
    //   margin-bottom: 0px;
    //   width: max-content;
    // }
    .leftBot img {
      width: 30%;
    }
    .form-control {
      margin-bottom: 13px;
      border: none;
      padding: 7px;
      width: 100%;
    }
  }
  @media (min-width: 1150px) and (max-width: 1330px) {
    min-height: 701px;
    .bannerCont {
      gap: 10px;
      justify-content: space-between;
    }
    .overrideWidth {
      width: 90%;
    }
    // .left .l1 {
    //   font-size: 34px;
    //   font-weight: 400;
    //   color: white;
    //   margin-bottom: 0px;
    // }
    // .left .l2 {
    //   font-size: 35px;
    //   font-weight: 800;
    //   color: white;
    //   line-height: 42px;
    //   margin-bottom: 0px;
    // }
    // .left .l3 {
    //   font-size: 42px;
    //   font-weight: 800;
    //   color: #f7cb3b;
    //   margin-bottom: 0px;
    //   width: max-content;
    // }
    .leftBot img {
      width: 30%;
    }
    .form-control {
      margin-bottom: 13px;
      border: none;
      padding: 7px;
      width: 100%;
    }
  }
  @media (min-width: 1330px) and (max-width: 1440px) {
    min-height: 800px;
    // .bannerCont {
    //   gap: 10px;
    //   justify-content: space-between;
    // }
    .overrideWidth {
      width: 90%;
    }
    // .left .l1 {
    //   font-size: 44px;
    //   font-weight: 400;
    //   color: white;
    //   margin-bottom: 0px;
    // }
    // .left .l2 {
    //   font-size: 40px;
    //   font-weight: 800;
    //   color: white;
    //   line-height: 52px;
    //   margin-bottom: 0px;
    // }
    // .left .l3 {
    //   font-size: 52px;
    //   font-weight: 800;
    //   color: #f7cb3b;
    //   margin-bottom: 0px;
    //   width: max-content;
    // }
    .leftBot img {
      width: 30%;
    }
  }
  @media (width: 1024px) {
    .overrideWidth {
      width: 50%;
    }
  }
  @media (min-width: 374px) and (max-width: 390px) {
    .leftBot img {
      width: 30%;
    }
  }
  @media (min-width: 320px) and (max-width: 374px) {
    .leftBot {
      margin-left: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .leftBot img {
      margin-left: 0px;

      width: 45%;
      height: auto;
    }
    .hPmNGO .logimg-div img {
      width: 20vw !important;
    }
  }
`;

const LandingBanner = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    contact: "",
    education: "",
    country: "",
    level: "",
    exam: "",
    city: "",
  });

  const [phoneError, setPhoneError] = useState("");
  const [digitError, setDigitError] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Manage submit button state

  const navigate = useNavigate(); // Corrected to useNavigate

  const validatePhoneStart = (value) => {
    if (value && !/^[6-9]/.test(value)) {
      setPhoneError("Phone number must start with 6, 7, 8, or 9");
    } else {
      setPhoneError("");
    }
  };

  const validatePhoneLength = (value) => {
    if (value.length !== 10) {
      setDigitError("Phone number must be of 10 digits");
    } else {
      setDigitError("");
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "fullname") {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(value)) {
        setError("Name can only contain letters and spaces.");
        return;
      }
    }

    if (name === "contact") {
      const sanitizedValue = value.replace(/\D/g, "");
      validatePhoneStart(sanitizedValue);

      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue.slice(0, 10),
      }));
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError(""); // Clear any error message
  };

  const handleBlur = (e) => {
    if (e.target.name === "contact") {
      validatePhoneLength(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setIsSubmitting(true);

    if (phoneError || digitError) {
      setError("Please correct the errors before submitting.");
      setIsSubmitting(false);
      return;
    }

    if (!/^[a-zA-Z\s]+$/.test(formData.fullname)) {
      setError("Name can only contain letters and spaces.");
      setIsSubmitting(false);
      return;
    }

    try {
      // Check if email already exists
      const emailQuery = query(
        collection(db, "leadstwo"),
        where("email", "==", formData.email)
      );
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        setError("Email already exists.");
        setIsSubmitting(false);
        return;
      }

      // Check if phone number already exists
      const phoneQuery = query(
        collection(db, "leadstwo"),
        where("contact", "==", formData.contact)
      );
      const phoneSnapshot = await getDocs(phoneQuery);

      if (!phoneSnapshot.empty) {
        setError("Phone number already exists.");
        setIsSubmitting(false);
        return;
      }

      // Add the submission date
      const submissionDate = new Date().toLocaleString();

      // Add the data to Firestore
      await addDoc(collection(db, "leadstwo"), { ...formData, submissionDate });
      console.log("Data saved to Firestore");

      // Redirect to the Thank You page
      navigate("/thank-you"); // Corrected navigation
    } catch (error) {
      console.error("Error saving data: ", error);
      setError("Failed to save data. Please try again.");
      setIsSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <div className="catch">
        <img className="logo" src={logo} alt="Logo" />
        <div className="bannerCont">
          <div className="left">
            <p className="l3">GLOBAL</p>
            <p className="l2">Education Fair 2024!</p>
            <p className="l1">
              Your Gateway to <br /> Global Success
            </p>
            <p className="lresp1">Top Universities Awaits</p>
            <p className="lresp2">Global Education Fair</p>
            <div className="leftBot">
              <img src={leftbot} alt="Left Bottom" />
            </div>
          </div>
          <div className="right">
            <div className="form">
              <Row className="form-row">
                <Col
                  className="overrideWidth"
                  xs={10}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={12}
                >
                  <div className="form-container">
                    <h2 className="form_h2 paddingForm">Register Now</h2>
                    <Form
                      className="textCenter paddingForm"
                      onSubmit={handleSubmit}
                    >
                      {error && <p className="error-message">{error}</p>}
                      {phoneError && (
                        <p className="error-message">{phoneError}</p>
                      )}
                      {digitError && (
                        <p className="error-message">{digitError}</p>
                      )}
                      {successMessage && (
                        <p style={{ color: "green" }}>{successMessage}</p>
                      )}
                      <Form.Group
                        controlId="formName"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          type="text"
                          placeholder="Name"
                          name="fullname"
                          value={formData.fullname}
                          onChange={handleInput}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="formEmail"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleInput}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="formPhoneNumber"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          type="tel"
                          placeholder="Phone Number"
                          name="contact"
                          value={formData.contact}
                          onChange={handleInput}
                          onBlur={handleBlur}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="formLocation"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          as="select"
                          name="city"
                          value={formData.city}
                          onChange={handleInput}
                          required
                        >
                          <option value="">Your City Name</option>
                          <option value="Manipal">Manipal</option>
                          <option value="Bangalore">Bangalore</option>
                          <option value="Chennai">Chennai</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Mumbai">Mumbai</option>
                          <option value="Vidyanagar">Vidyanagar</option>
                          <option value="Vadodara">Vadodara</option>
                          <option value="Ahmedabad">Ahmedabad</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="formLocation"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          as="select"
                          name="country"
                          value={formData.country}
                          onChange={handleInput}
                          required
                        >
                          <option value="">
                            Your Preferred Study Destination
                          </option>
                          <option value="Australia">Australia</option>
                          <option value="USA">USA</option>
                          <option value="Canada">Canada</option>
                          <option value="UK">UK</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="formEducationLevel"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          as="select"
                          name="education"
                          value={formData.education}
                          onChange={handleInput}
                          required
                        >
                          <option value="">Current Level of Education</option>
                          <option value="12th Pass">12th Pass</option>
                          <option value="Graduate">Graduate</option>
                          <option value="Post Graduate">Post Graduate</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="formStudyLevel"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          as="select"
                          name="level"
                          value={formData.level}
                          onChange={handleInput}
                          required
                        >
                          <option value="">Preferred Study Level</option>
                          <option value="Graduate">Graduate</option>
                          <option value="Post Graduate">Post Graduate</option>
                          <option value="Doctorate">Doctorate</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="formExam"
                        className="margin-bottom borderRad"
                      >
                        <Form.Control
                          className="fntSize"
                          as="select"
                          name="exam"
                          value={formData.exam}
                          onChange={handleInput}
                          required
                        >
                          <option value="">
                            Have you taken up IELTS/PTE/TOEFL exam?
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Form.Control>
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="margin-bottom formButton borderRad"
                        style={{
                          backgroundColor: "#152A68",
                          borderColor: "#152A68",
                        }}
                        disabled={isSubmitting}
                      >
                        Register Now
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default LandingBanner;
