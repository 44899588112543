import React from "react";
import styled from "styled-components";
import one from "../assets/images/eventB.webp";
import two from "../assets/images/eventC.webp";
import three from "../assets/images/eventD.webp";
import four from "../assets/images/eventE.webp";
import five from "../assets/images/eventF.webp";

import { Container, Row, Col } from "react-bootstrap";

const Events = () => {
  const Events = styled.section`
    width: 100%;
    .customGap {
      gap: 2.4vw;
    }

    .customGapOne {
      gap: 0.4vw;
    }

    .cont {
      width: 100%;
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
    }
    h2 {
      font-size: 35px;
      font-weight: 700;
      color: #122345;
      text-align: center;
    }
    .underline {
      position: relative;
      cursor: pointer;
    }
    .underline::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 0%;
      background-color: #122345;
      transition: all ease 1s;
    }
    .underline:hover::after {
      width: 100%;
    }
    img {
      width: 30%;
    }

    @media (max-width: 768px) {
      .underline {
        font-size: 4vw !important;
      }
    }

    @media (max-width: 450px) {
      .underline {
        font-size: 24px !important;
      }
    }

    @media (max-width: 450px) {
      /* background-color: #eef1f3;
      padding: 20px 0; */
      // .customGap {
      //   gap: 0px;
      // }
      .eventsTitle {
        margin-top: 20px;
      }
      .fdColumn {
        flex-direction: column;
      }
      .cont {
        flex-direction: column;
      }
      img {
        width: 60%;
      }
      h2 {
        font-size: 28px;
        font-weight: 700;
      }
      .underline::after {
        height: 3px;
      }
    }
  `;
  return (
    <>
      <Events>
        <div className="eventsTitle">
          <h2>
            <span className="underline">Events</span>
          </h2>
        </div>
        {/* <div className="cont">
          <img src={three} alt="" />
          <img src={one} alt="" />
          <img src={two} alt="" />
          <img src={two} alt="" />
          <img src={two} alt="" />
        </div> */}
        <Row>
          <Col md={12}>
            <Row>
              <Col xl={12}>
                <Container className="d-flex align-items-center justify-content-around customGapOne fdColumn">
                  <img src={two} alt="" />
                  <img src={one} alt="" />
                  <img src={three} alt="" />
                </Container>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <Col>
                <Container className="d-flex align-items-center justify-content-center customGap fdColumn">
                  <img src={four} alt="" />
                  <img src={five} alt="" />
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>
      </Events>
    </>
  );
};

export default Events;
