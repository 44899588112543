import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { GlobalStyle } from "./components/globalStyles";
import LandingPage from "./components/LandingPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThankYouPage from "./components/Thankyou";

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
