import React from "react";
import LandingBanner from "./LandingBanner";
import Events from "./Events";
import Partners from "./Partners";
import WhyPlanet from "./WhyPlanet";
import Education from "./Education";
import Testimonial from "./Testimonial";
import Footer from "./Footer";

const LandingPage = () => {
  return (
    <>
      <LandingBanner />
      <Events />
      <Partners />
      <Education />
      <WhyPlanet />
      <Testimonial />
      <Footer />
    </>
  );
};

export default LandingPage;
